import {
  animate,
  scroll,
  inView,
  stagger,
  timeline,
  TimelineDefinition,
  ScrollOptions,
} from 'motion'

const heroImg = document.querySelector('.hero-img')
if (heroImg) {
  const options: ScrollOptions = {
    target: heroImg,
    offset: ['start start', 'end start'],
  }
  scroll(animate(heroImg, { scale: [1, 1.3] }), options)
}

document.querySelectorAll('[data-fade-scroll]').forEach((item) => {
  scroll(animate(item, { opacity: [0, 1, 1, 0] }), {
    target: item,
    offset: ['start end', 'end end', 'start start', 'end start'],
  })
})

document.querySelectorAll('[data-fade-scroll-history]').forEach((item) => {
  scroll(animate(item, {
    opacity: [0, 1, 1, 0],
    // y: [-50, 50, 50, 100]
  }), {
    target: item,
    offset: ['start end', 'end end', 'start start', 'end start'],
  })
})

let mq = window.matchMedia('(min-width: 768px)')

document.querySelectorAll('[data-speed]').forEach((item) => {
  if (item instanceof HTMLElement) {
    const speedSetting = item.dataset.speed;
    const onMobile = item.dataset.mobile !== "false";

    if (speedSetting && onMobile || mq.matches) {
      const speed = parseFloat(speedSetting || '100')
      scroll(animate(item, { y: [-speed, speed] }), {
        target: item,
        offset: ['end start', 'start end'],
      })
    }
  }
})

// Slide in
inView(
  '[data-slide]',
  ({ target }) => {
    animate(
      target,
      { opacity: [0, 1], x: [100, 0] },
      { delay: 0.2, duration: 0.9, easing: [0.17, 0.55, 0.55, 1] },
    )
  },
  {
    amount: 0.05,
  },
)

inView(
  '[data-appear]',
  ({ target }) => {
    animate(
      target,
      { opacity: 1, y: [100, 0], transform: 'none' },
      { delay: 0.3, duration: 0.9, easing: [0.17, 0.55, 0.55, 1] },
    )
  },
  {
    amount: 0.05,
  },
)

inView(
  '[data-appear-children]',
  ({ target }) => {
    animate(
      target.querySelectorAll(':scope > *'),
      { opacity: [0, 1], y: [100, 0], transform: 'none' },
      { delay: stagger(0.2), duration: 0.9, easing: 'ease-in-out' },
    )
  },
  {
    amount: 0.2,
  },
)

// Gallery Images
const galleryImgs: NodeListOf<HTMLImageElement> = document.querySelectorAll('[data-gallery] img')
galleryImgs.forEach((img, idx) => {
  // img.style.opacity = '0';
  const speed = [[20, 20], [20, -20], [-20, 20], [-20, -20], [20, -20]][idx];

  if (speed) {
    scroll(animate(img, {
      y: [-speed[0], speed[0]],
      x: [-speed[1], speed[1]],
    }), {
      target: img,
      offset: ['end start', 'start end'],
    })
  }
});

const listEls: NodeListOf<HTMLImageElement> = document.querySelectorAll(
  '[data-list-appear] li',
)
listEls.forEach((el) => (el.style.opacity = '0'))
inView('[data-list-appear]', ({ target }) => {
  const els = target.querySelectorAll('li')
  animate(
    els,
    { opacity: 1, transform: 'none' },
    { duration: 0.9, easing: [0.17, 0.55, 0.55, 1], delay: stagger(0.15) },
  )
})

declare global {
  interface Window {
    animate:any;
    stagger:any;
  }
}

window.animate = animate
window.stagger = stagger
