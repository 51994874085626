import Alpine from 'alpinejs'
import interset from '@alpinejs/intersect'
import anchor from '@alpinejs/anchor'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'

// import { Slider } from '@js/slider.ts'
import './animations.ts'

window.Alpine = Alpine

Alpine.plugin(interset)
Alpine.plugin(anchor)
Alpine.plugin(focus)
Alpine.plugin(collapse)

// Alpine.data('slider', Slider)
Alpine.start()
